
















import { Component, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import XTabs from "@/components/SimpleTabs.vue";
import ParamTabContent from "@/components/for-contract-card/ParamTabContent.vue";
import PointTabContent from "@/components/for-contract-card/PointTabContent.vue";
import { mapGetters } from "vuex";

@Component({
  components: {
    Page,
    XTabs,
    ParamTabContent,
    PointTabContent,
  },
  computed: {
    ...mapGetters("contract", { contractType: "type" }),
  },
})
class ContractCard extends Vue {
  titles = ["Характеристики", "Точки учета"];
  components = [ParamTabContent, PointTabContent];

  activeTabIndex = 0;
}

export default ContractCard;
