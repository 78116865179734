var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box box_as_responsive-page"},[_c('x-card-with-list',{staticClass:"card_type_contract-details",attrs:{"title":_vm.commonContractInfo.title,"items":_vm.contractDetails,"split-card-section":true},scopedSlots:_vm._u([{key:"beforeTitle",fn:function(){return [_c('contract-icon',{staticClass:"card__icon",attrs:{"contract-type":_vm.commonContractInfo.type,"size":"xl"}})]},proxy:true},{key:"afterTitle",fn:function(){return [_c('span',{staticClass:"card__subtitle"},[_vm._v(_vm._s(_vm.commonContractInfo.subtitle))])]},proxy:true}])}),_c('x-card-with-list',{attrs:{"title":"Срок действия","items":_vm.contractTerms,"list-mod-class":"list_card_contract-terms list_grid_responsive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_item = ref.item;
var key = ref_item.key;
var value = ref_item.value;
return [_c('span',{staticClass:"list__key list__key_size_xs"},[_vm._v(_vm._s(key))]),_c('v-icon',{staticClass:"icon",attrs:{"small":""}},[_vm._v("mdi-calendar")]),_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(value || "—")))])]}}])}),_c('x-card-with-list',{attrs:{"items":_vm.contractContacts,"list-mod-class":"list_card_contract-contacts"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_item = ref.item;
var mdi = ref_item.mdi;
var key = ref_item.key;
var value = ref_item.value;
return [_c('v-icon',{staticClass:"icon icon_size_lg icon_color_base rounded"},[_vm._v(" "+_vm._s(mdi)+" ")]),_c('span',{staticClass:"list__key"},[_vm._v(_vm._s(key))]),_c('span',[_vm._v(_vm._s(value || "—"))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }